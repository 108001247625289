<span *ngIf="user">
  <span *ngIf="width > 767">
    <div class="row p-3 border mb-4">
      <div class="col">
        <div class="row justify-content-center mb-3">
          <img [src]="user.photoURL" class="rounded-circle" max-width="64px" />
        </div>
        <div class="row justify-content-center mb-1">
          {{ user.displayName }}
        </div>
        <div class="row justify-content-center mb-3" *ngIf="isAdmin">
          <small class="text-muted">
            <i class="fas fa-crown mr-1"></i> Admin
          </small>
        </div>
        <!-- <div class="row justify-content-center mb-2">
          <button class="btn dark-outline-button rounded-0 px-3">
            <small>Make Profile Public</small>
          </button>
        </div> -->
        <div class="row justify-content-center mb-2" *ngIf="isOwn">
          <button
            (click)="signOut()"
            *ngIf="isOwn"
            class="btn dark-outline-button rounded-0 px-3"
          >
            <small>Sign Out</small>
          </button>
        </div>
      </div>
    </div>
    <div class="row border mt-4">
      <div class="col">
        <span *ngFor="let section of sections" class="sub-title">
          <div
            class="row pl-4 py-2 accNavElem"
            [id]="section.id"
            [routerLink]="'/user/' + user.uid + '/' + section.id"
          >
            {{ section.name }}
          </div>
        </span>
      </div>
    </div>
  </span>
  <span *ngIf="width <= 767">
    <div class="row">
      <div class="col p-3 border mr-1">
        <div class="row justify-content-center mb-3">
          <img [src]="user.photoURL" class="rounded-circle" max-width="64px" />
        </div>
        <div class="row justify-content-center mb-1">
          {{ user.displayName }}
        </div>
        <div class="row justify-content-center mb-3" *ngIf="isAdmin">
          <small class="text-muted">
            <i class="fas fa-crown mr-1"></i> Admin
          </small>
        </div>
        <div class="row justify-content-center mb-2" *ngIf="isOwn">
          <button
            (click)="signOut()"
            class="btn dark-outline-button rounded-0 px-3"
          >
            <small>Sign Out</small>
          </button>
        </div>
      </div>
      <div class="col border">
        <span *ngFor="let section of sections" class="sub-title">
          <div
            class="row pl-4 py-2 accNavElem"
            [id]="section.id"
            [routerLink]="'/user/' + user.uid + '/' + section.id"
          >
            {{ section.name }}
          </div>
        </span>
      </div>
    </div>
  </span>
</span>
