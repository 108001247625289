<hr class="mt-0" style="background-color: white" />
<small>
  <div class="row ml-1 mb-3 text-white">
    <div class="mr-3">{{ numViews }} <i class="fas fa-eye"></i></div>
    <div class="mr-3">{{ numComments }} <i class="fas fa-comments"></i></div>
    <div class="ml-auto mr-3">
      {{ numLikes }}
      <i
        *ngIf="liked"
        (click)="unlike(post.id)"
        class="fas fa-heart text-danger"
      ></i>
      <i
        *ngIf="!liked"
        (click)="like(post.id)"
        class="far fa-heart text-danger"
      ></i>
    </div>
  </div>
</small>
