<footer
  class="page-footer font-small text-white mt-3"
  style="background-color: rgba(0, 0, 0, 0.9)"
>
  <div class="container text-center text-md-left py-3">
    <div class="row text-center">
      <!-- <div class="col-sm-12 col-md-3 p-3">
        <img width="128px" src="../../../../assets/STLOGO.png" />
      </div> -->
      <!-- <div class="col">
        <div class="row">
          <a class="mx-auto my-1 text-big footTitle sub-title"
            ><u>Community</u></a
          >
        </div>
        <div class="row">
          <a href="/blog" class="mx-auto my-1">All Blog Posts</a>
        </div>
        <div class="row">
          <a href="/blog-events" class="mx-auto my-1">Events</a>
        </div>
        <div class="row">
          <a href="/blog-podcasts" class="mx-auto my-1">Podcasts</a>
        </div>
        <div class="row">
          <a href="/community" class="mx-auto my-1">Community Forum</a>
        </div>
      </div>
      <div class="col">
        <div class="row">
          <a class="mx-auto my-1 text-big footTitle sub-title"><u>Pages</u></a>
        </div>
        <div class="row">
          <a href="/" class="mx-auto my-1">Home</a>
        </div>
        <div class="row">
          <a href="/services" class="mx-auto my-1">Services</a>
        </div>
        <div class="row">
          <a href="/about" class="mx-auto my-1">About</a>
        </div>
      </div> -->
      <div class="col">
        <div class="row">
          <a class="mx-auto my-1 text-big footTitle sub-title"
            ><u>Other Links</u></a
          >
        </div>
        <div class="row justify-content-center my-1">
          <div class="col-sm-6 col-md-3 text-center">
            <a href="/login" class="mx-auto my-1">Profile</a>
          </div>
          <div class="col-sm-6 col-md-3 text-center">
            <a href="/bug-report" class="mx-auto my-1">Report An Issue</a>
          </div>
          <div class="col-sm-6 col-md-3 text-center">
            <a href="/terms" class="mx-auto my-1">Terms of Service</a>
          </div>
          <div class="col-sm-6 col-md-3 text-center">
            <a href="/privacy" class="mx-auto my-1">Privacy Policy</a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <small>
    <div
      class="footer-copyright text-center py-3"
      style="background-color: rgba(0, 0, 0, 0.85)"
    >
      &copy; 2020 Copyright:
      <span>Stronger Together,</span>
      Built by Akathian Santhakumar
      <a href="https://github.com/Akathian"
        ><i class="fa fa-github" style="font-size: 1.2em"></i
      ></a>
    </div>
  </small>
</footer>
