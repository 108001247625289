<span class="commentContent">
  <div class="row px-2 pt-2" [id]="comment.id">
    <div class="mr-2">
      <a
        [href]="'/user/' + comment.uid + '/profile'"
        *ngIf="comment.authorName !== 'Anonymous' || isAdmin"
      >
        <img [src]="comment.authorImg" class="rounded-circle author-img-big" />
      </a>
      <a *ngIf="comment.authorName === 'Anonymous' && !isAdmin">
        <img [src]="comment.authorImg" class="rounded-circle author-img-big" />
      </a>
    </div>
    <div class="">
      <a
        [href]="'/user/' + comment.uid + '/profile'"
        *ngIf="comment.authorName !== 'Anonymous' || isAdmin"
      >
        {{ comment.authorName }}
      </a>
      <a *ngIf="comment.authorName === 'Anonymous' && !isAdmin">
        {{ comment.authorName }}
      </a>
      <br />
      <small
        ><small class="text-muted">{{ comment.time | timeParse }}</small></small
      >
    </div>
    <div class="ml-auto">
      <app-gear class="align-self-center" [data]="gearData"></app-gear>
    </div>
  </div>
  <div class="row ml-5 mr-2 mb-2">
    {{ comment.content }}
  </div>

  <div class="col ml-4 my-2" *ngIf="comment.replies || user">
    <div class="mb-3">
      <div [id]="'id' + comment.id" role="tabpanel">
        <div *ngFor="let reply of comment.replies | keyvalue" class="col m-3">
          <app-forum-replies [reply]="reply.value"></app-forum-replies>
        </div>
        <div class="col m-3" *ngIf="user">
          <hr class="mr-5" />
          <span class="replyContent">
            <div class="row">
              <div class="mr-2">
                <div class="row">
                  <svg
                    width="1em"
                    height="1em"
                    viewBox="0 0 16 16"
                    class="bi bi-arrow-return-right mr-1 align-self-center"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M1.5 1.5A.5.5 0 0 0 1 2v4.8a2.5 2.5 0 0 0 2.5 2.5h9.793l-3.347 3.346a.5.5 0 0 0 .708.708l4.2-4.2a.5.5 0 0 0 0-.708l-4-4a.5.5 0 0 0-.708.708L13.293 8.3H3.5A1.5 1.5 0 0 1 2 6.8V2a.5.5 0 0 0-.5-.5z"
                    />
                  </svg>
                  <img
                    [src]="user.photoURL"
                    class="rounded-circle author-img-small"
                  />
                </div>
              </div>
              <div class="col justify-content-between">
                <small>
                  <div class="row justify-content-between">
                    <div class="col-sm-7 col-md-8 col-lg-9">
                      {{ user.displayName }}
                    </div>
                    <div class="col col-md-4 col-lg-3"></div>
                  </div>
                </small>
              </div>
            </div>
            <!-- <div class="row ml-4 mr-5">
                <small class="">Write a reply</small>
              </div> -->
            <div class="row ml-4 mr-5 newReply">
              <div class="col-12 ml-0 pl-0">
                <textarea
                  class="form-control"
                  placeholder="Write a reply..."
                  id="replyFormArea"
                  rows="1"
                  (change)="replyChange($event.target.value)"
                ></textarea>
              </div>
              <div class="">
                <button
                  class="btn dark-solid-button px-2 py-0 rounded-0 m-1 newReply"
                  (click)="postReply('')"
                >
                  <small>Post</small>
                </button>
                <button
                  class="btn dark-outline-button px-2 py-0 rounded-0 m-1 newReply"
                  (click)="postReply('anon')"
                >
                  <small>Post Anonymously</small>
                </button>
              </div>
            </div>
          </span>
        </div>
      </div>
    </div>
  </div>
</span>
