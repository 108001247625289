<div class="container-fluid">
  <small>
    <a href="/">Stronger Together </a> /
    <a href="/community"> Community </a>
  </small>
  <hr class="m-0" />
</div>
<div class="row my-3 p-5 m-5">
  <div class="col">
    <div class="row">
      <h1
        class="text-center text-uppercase mx-auto"
        data-aos="fade-up"
        data-aos-easing="ease-in-out"
        data-aos-duration="1000"
        data-aos-offset="300"
      >
        Community
      </h1>
    </div>
  </div>
</div>
<div class="container mt-5">
  <div class="col">
    <div class="row">
      <div class="col">
        <div class="row justify-content-center">
          <span class="">
            A safe space where you can journal about your day, your problems,
            etc., in which people can reply providing emotional support, help,
            and guidance. Together, we can promote healing, hope, strength,
            resiliency, trust, and support.
          </span>
        </div>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col">
        <div class="row border border-bottom-0 justify-content-end p-2">
          <div class="col-6 mr-auto ml-3">
            <div class="row justify-content-start sub-title">
              <div class="mr-3">
                <a href="/community"> All Posts </a>
              </div>
              <div class="mr-3">
                <a href="/my-community-posts"> My Posts</a>
              </div>
              <button (click)="newPost()" class="dropbtn gearBtn">
                Create New Post
              </button>
            </div>
          </div>
          <div class="col-1 text-center">
            <i class="fas fa-comments"></i>
          </div>
          <div class="col-1 text-center">
            <i class="far fa-heart"></i>
          </div>
          <div class="col-1 text-center">
            <i class="fas fa-eye"></i>
          </div>
        </div>
        <div class="row border">
          <div class="col">
            <div *ngFor="let forum of postsData" class="row forum p-2">
              <div class="col">
                <div class="row">
                  <div class="col-6 mr-auto">
                    <div class="row">
                      <div class="col">
                        <a [href]="'/community/' + forum.id">
                          {{ forum.title }}
                        </a>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col">
                        <small>
                          <small>
                            <a [href]="'/user/' + forum.uid + '/profile'">
                              {{ forum.authorName }}
                            </a>
                          </small>
                        </small>
                      </div>
                    </div>
                  </div>
                  <div class="col-1 text-center my-auto">
                    {{ forum.numComments }}
                  </div>
                  <div class="col-1 text-center my-auto">
                    {{ forum.numLikes }}
                  </div>
                  <div class="col-1 text-center my-auto">
                    {{ forum.numViews }}
                  </div>
                </div>
              </div>

              <div class="col-12">
                <hr class="m-0" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="d-flex justify-content-center mt-3" *ngIf="numPages === 0">
    <i>No Posts Yet</i>
  </div>
  <div class="d-flex justify-content-center" *ngIf="numPages > 0">
    <!-- <div class="col" *ngFor="let page of numPages">
    {{ page + 1 }}
  </div> -->
    <div class="align-self-center">
      <button class="btn" (click)="goToPage(1)"><<</button>
    </div>
    <div class="align-self-center">
      <button class="btn" (click)="goToPage(page - 1)"><</button>
    </div>

    <div class="align-self-center">{{ page }} of {{ numPages }}</div>
    <div class="align-self-center">
      <button class="btn" (click)="goToPage(page + 1)">></button>
    </div>
    <div class="align-self-center">
      <button class="btn" (click)="goToPage(numPages)">>></button>
    </div>
  </div>
</div>
