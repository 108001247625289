<span *ngIf="!userLoggedIn">
  <div class="container-fluid">
    <small>
      <a href="/">Stronger Together </a> /
      <a href="/login"> Login </a>
    </small>
    <hr class="m-0" />
  </div>
  <h4 class="text-center m-3">Sign in/ Sign up</h4>
</span>
<div id="firebaseui-auth-container"></div>
<span *ngIf="userLoggedIn">
  <div class="container-fluid">
    <small>
      <a href="/">Stronger Together </a> /
      <a [href]="currPath"> {{ username }} </a>
    </small>
    <hr class="m-0" />
  </div>
  <div class="container mt-5">
    <div class="row">
      <div class="col-lg-4 col-md-4 col-sm-12">
        <app-acc-nav [user]="user" [sections]="sections"></app-acc-nav>
      </div>
      <div class="col">
        <app-profile *ngIf="section === 'profile'"></app-profile>
        <!-- <app-bookings *ngIf="section === 'bookings'"></app-bookings> -->
        <app-blog-posts *ngIf="section === 'blog-posts'"></app-blog-posts>
        <app-blog-likes *ngIf="section === 'blog-likes'"></app-blog-likes>
        <app-blog-comments
          *ngIf="section === 'blog-comments'"
        ></app-blog-comments>
        <app-drafts *ngIf="section === 'drafts'"></app-drafts>
        <!-- <app-forum-posts *ngIf="section === 'comm-posts'"></app-forum-posts> -->
        <app-user-forum-comments
          *ngIf="section === 'user-community-comments'"
        ></app-user-forum-comments>
        <app-forum-drafts
          *ngIf="section === 'community-drafts'"
        ></app-forum-drafts>
        <app-forum-likes
          *ngIf="section === 'community-likes'"
        ></app-forum-likes>
      </div>
    </div>
  </div>
</span>
