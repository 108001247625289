<div class="d-flex flex-column justify-content-center p-5 m-5">
  <div>
    <h1
      data-aos="fade-up"
      data-aos-easing="ease-in-out"
      data-aos-duration="1000"
      data-aos-offset="300"
      data-aos-once="true"
      class="text-center text-uppercase"
    >
      Stronger Together
    </h1>
    <h3
      data-aos="fade-up"
      data-aos-easing="ease-in-out"
      data-aos-duration="1000"
      data-aos-once="true"
      class="text-center"
    >
      Healing, Hope, Strength, Resiliency, Trust, and Support
    </h3>
  </div>
</div>
