<div
  class="row justify-content-center text-white"
  style="background-color: #003459"
  id="contact"
>
  <div class="container p-5">
    <div class="row">
      <div class="col">
        <h2 class="text-uppercase text-center">Contact</h2>
      </div>
    </div>
    <div class="row">
      <div
        class="col-sm-12 col-md-6 align-self-center"
        data-aos="fade-right"
        data-aos-duration="1000"
        data-aos-once="true"
        data-aos-offset="150"
      >
        <div class="row justify-content-center">
          <div class="col">
            <p class="text">
              If you would like to be a part of the Stronger Together Family and
              share your story, please feel free to contact us through

              <a
                class="homeLink"
                href="https://www.facebook.com/Stronger.Together100/"
                >Facebook</a
              >
              ,
              <a
                class="homeLink"
                href="https://www.instagram.com/s.strongertogether/"
                >Instagram DM</a
              >
              or
              <a class="homeLink" href="mailto:s.strongertogether@gmail.com"
                >Email</a
              >. You can also
              <a class="homeLink" href="https://forms.gle/iPEZkgW6ZJCp6Yqz5"
                >submit your stories anonymously here</a
              >. Other services we provide beyond our social media platform,
              include life coaching, and peer buddy support. As we continue to
              grow, we will host conferences and events to continuously raise
              awareness and promote safe spaces where we can find support and
              comfort within each other. We thank you for your support in our
              mission and journey.
            </p>
          </div>
        </div>

        <div class="row justify-content-center">
          <div class="fb mr-2">
            <a
              class="text-white"
              href="https://www.facebook.com/Stronger.Together100/"
            >
              <i class="fa fa-facebook-official" style="font-size: 2em"></i>
            </a>
          </div>
          <div class="ig mr-2">
            <a
              class="text-white"
              href="https://www.instagram.com/s.strongertogether/"
            >
              <i class="fa fa-instagram" style="font-size: 2em"></i>
            </a>
          </div>
          <div class="li">
            <a
              class="text-white"
              href="http://linkedin.com/company/s-stronger-together"
            >
              <i class="fa fa-linkedin-square" style="font-size: 2em"></i>
            </a>
          </div>
        </div>
      </div>
      <div
        class="d-flexcol-sm-12 col-md-6 px-5 p-2 text-center"
        data-aos="fade"
        data-aos-duration="1000"
        data-aos-once="true"
        data-aos-offset="200"
      >
        <div class="align-self-center">
          <form
            [formGroup]="lifeCoachForm"
            (ngSubmit)="onSubmit(lifeCoachForm.value)"
            class="mb-3"
          >
            <div class="container-fluid">
              <div class="row">
                <div class="col mr-1" id="life-fname">
                  <div class="row">
                    <label>
                      First Name:
                      <span class="text-danger small" *ngIf="fnameErr"
                        >*Required</span
                      >
                    </label>
                    <input
                      type="text"
                      class="form-control mb-4 rounded-0"
                      placeholder="Name"
                      formControlName="fname"
                    />
                  </div>
                </div>
                <div class="col mr-1" id="life-email">
                  <div class="row">
                    <label>
                      Email:
                      <span class="text-danger small" *ngIf="emailErr"
                        >*Required</span
                      >
                    </label>
                    <input
                      type="text"
                      class="form-control mb-4 rounded-0"
                      placeholder="example@domain.com"
                      formControlName="email"
                    />
                  </div>
                </div>
              </div>
              <div class="row" id="life-contact">
                <div class="col" id="life-phone">
                  <div class="row">
                    <label>
                      Message:
                      <span class="text-danger small" *ngIf="messageErr"
                        >*Required</span
                      >
                    </label>
                    <input
                      type="text"
                      class="form-control mb-4 rounded-0"
                      placeholder="Your message here"
                      formControlName="message"
                    />
                  </div>
                </div>
              </div>
            </div>
            <button
              class="btn primary-solid-button btn-block rounded-0"
              id="lifeSubmit"
              type="submit"
            >
              <span *ngIf="!sent && !showLoading && !error">Submit</span>
              <img
                src="../../../../assets/loading.gif"
                height="16px"
                *ngIf="showLoading"
              />
              <span *ngIf="sent && !showLoading && !error">Sent!</span>
              <span *ngIf="error && !showLoading">Error, try again</span>
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
