<div class="container">
  <div class="row mt-2">
    <div class="col">
      <h5>Community Drafts</h5>
      <hr />
    </div>
  </div>
  <div class="row">
    <div class="col" *ngIf="!showLoading && lenOfData">
      <div class="row" *ngFor="let draft of drafts | keyvalue">
        <div class="col">
          <a [href]="href + draft.key + '/edit'"
            >{{ draft.value.title }} created
            {{ draft.value.time | timeParse }}</a
          >
        </div>
      </div>
    </div>
    <div class="col text-center" *ngIf="!showLoading && !lenOfData">
      No Drafts Yet
    </div>
    <div class="col text-center" *ngIf="showLoading">
      <img src="../../../../assets/loading.gif" />
    </div>
  </div>
</div>
