<hr class="mr-5" />
<!-- <span class="replyContent"> -->
<small>
  <div class="row px-2 pt-2" [id]="reply.id">
    <div class="mr-2">
      <svg
        width="1em"
        height="1em"
        viewBox="0 0 16 16"
        class="bi bi-arrow-return-right mr-1 align-self-center"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          d="M1.5 1.5A.5.5 0 0 0 1 2v4.8a2.5 2.5 0 0 0 2.5 2.5h9.793l-3.347 3.346a.5.5 0 0 0 .708.708l4.2-4.2a.5.5 0 0 0 0-.708l-4-4a.5.5 0 0 0-.708.708L13.293 8.3H3.5A1.5 1.5 0 0 1 2 6.8V2a.5.5 0 0 0-.5-.5z"
        />
      </svg>
      <a
        [href]="'/user/' + reply.uid + '/profile'"
        *ngIf="reply.authorName !== 'Anonymous' || isAdmin"
      >
        <img
          [src]="reply.authorImg"
          class="rounded-circle author-img-small"
          width="24px"
        />
      </a>
      <a *ngIf="reply.authorName === 'Anonymous' && !isAdmin">
        <img
          [src]="reply.authorImg"
          class="rounded-circle author-img-small"
          width="24px"
        />
      </a>
    </div>
    <div class="">
      <a
        [href]="'/user/' + reply.uid + '/profile'"
        *ngIf="reply.authorName !== 'Anonymous' || isAdmin"
      >
        {{ reply.authorName }}
      </a>
      <a *ngIf="reply.authorName === 'Anonymous' && !isAdmin">
        {{ reply.authorName }}
      </a>
      <br />
      <small>
        <small class="text-muted">
          {{ reply.time | timeParse }}
        </small>
      </small>
    </div>
    <div class="ml-auto">
      <app-gear class="align-self-center" [data]="gearData"></app-gear>
    </div>
  </div>
  <div class="row ml-5 mr-2 mb-2">
    {{ reply.content }}
  </div>
</small>
<!-- </span> -->
