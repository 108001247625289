<div class="container-fluid">
  <small> <a href="/">Stronger Together </a> / </small>
  <hr class="m-0" />
</div>

<app-title></app-title>
<!-- <app-slideshow></app-slideshow> -->
<app-glance></app-glance>
<app-quote></app-quote>
<app-contact></app-contact>
