<div class="container">
  <div class="row mt-2">
    <div class="col">
      <h5>Blog Posts</h5>
      <hr class="mb-3" />
    </div>
  </div>
  <div class="row">
    <div class="col align-self-center text-center" *ngIf="showLoading">
      <img src="../../../../assets/loading.gif" />
    </div>
    <div class="col" *ngIf="!showLoading && lenOfData">
      <div class="row mb-3" *ngFor="let post of postsData">
        <div class="col">
          <a [href]="'/blog/' + post.id">
            <small class="text-muted mr-3"
              ><i>{{ post.time | timeParse }}</i></small
            >
            {{ post.title }}
          </a>
        </div>
      </div>
    </div>
    <div class="col text-center" *ngIf="!showLoading && !lenOfData">
      No Posts Yet
    </div>
  </div>
  <div class="d-flex justify-content-center" *ngIf="!showLoading && lenOfData">
    <!-- <div class="col" *ngFor="let page of numPages">
      {{ page + 1 }}
    </div> -->
    <div class="align-self-center">
      <button class="btn" (click)="goToPage(1)"><<</button>
    </div>
    <div class="align-self-center">
      <button class="btn" (click)="goToPage(page - 1)"><</button>
    </div>

    <div class="align-self-center">{{ page }} of {{ numPages }}</div>
    <div class="align-self-center">
      <button class="btn" (click)="goToPage(page + 1)">></button>
    </div>
    <div class="align-self-center">
      <button class="btn" (click)="goToPage(numPages)">>></button>
    </div>
  </div>
</div>
