<div class="container-fluid">
  <small>
    <a href="/">Stronger Together </a> /
    <a href="/services"> Services </a>
  </small>
  <hr class="m-0" />
</div>

<div class="d-flex flex-column justify-content-center my-3 py-5 m-5">
  <div>
    <h1
      class="text-center text-uppercase"
      data-aos="fade-up"
      data-aos-easing="ease-in-out"
      data-aos-duration="1000"
      data-aos-offset="300"
    >
      Services
    </h1>
    <h3
      class="text-center"
      data-aos="fade-up"
      data-aos-easing="ease-in-out"
      data-aos-duration="1000"
    >
      Collaborative & Innovative
    </h3>
  </div>
</div>
<div class="container-fluid">
  <div class="row">
    <div class="col">
      <app-peer></app-peer>
      <app-life></app-life>
    </div>
  </div>
</div>
