<div
  class="d-flex flex-column justify-content-center text-center p-5"
  style="background-color: #f4f4f4"
  data-aos="fade"
  data-aos-duration="1000"
  data-aos-once="true"
>
  <h2 *ngIf="!loggedIn">Join the Family</h2>
  <div id="firebaseui-auth-container"></div>
  <div class="mx-5 mb-5" *ngIf="!loggedIn">
    <p>“We may not have it all together, but together we can have it all.”</p>
    <p>- Stronger Together Family</p>
  </div>
  <div class="m-5" *ngIf="loggedIn">
    <p>“We may not have it all together, but together we can have it all.”</p>
    <p>- Stronger Together Family</p>
  </div>
</div>
