<div class="container-fluid">
  <small>
    <a href="/">Stronger Together </a> /
    <a [href]="currPath"> Blog {{ path }} </a></small
  >
  <hr class="m-0" />
</div>
<div class="row my-3 p-5 m-5">
  <div class="col">
    <div class="row">
      <h1
        class="text-center text-uppercase mx-auto"
        data-aos="fade-up"
        data-aos-easing="ease-in-out"
        data-aos-duration="1000"
        data-aos-offset="300"
      >
        Blog
      </h1>
    </div>
  </div>
</div>
<div class="container mt-5">
  <h3 *ngIf="pinnedPosts.length">Pinned Posts</h3>
  <span *ngIf="pinnedPosts.length">
    <div
      class="row border my-3 p-3 text-white"
      *ngFor="let pinned of pinnedPosts"
      style="background-color: #003459"
      data-aos="fade-right"
      data-aos-once="true"
      data-aos-duration="1000"
      data-aos-offset="100"
    >
      <div class="d-flex col px-5 p-2">
        <div class="align-self-center" width="100%">
          <div class="mb-2">
            <small class="align-self-center">
              <div class="row justify-content-start">
                <div class="p-0 ml-3">
                  <a [href]="'/user/' + pinned.uid + '/profile'">
                    <img
                      class="rounded-circle"
                      [src]="pinned.authorImg"
                      width="32px"
                    />
                  </a>
                </div>
                <div class="d-flex flex-column p-0 ml-3 justify-content-center">
                  <div class="">
                    <a [href]="'/user/' + pinned.uid + '/profile'">
                      {{ pinned.authorName }}
                    </a>
                  </div>
                  <div class="">
                    <small>
                      {{ pinned.time | timeParse }} &middot;
                      {{ pinned.readTime | readParse }}
                    </small>
                  </div>
                </div>
                <div class="col-3 ml-auto" *ngIf="isAdmin">
                  <div class="row">
                    <button
                      class="btn my-auto rounded-0 ml-auto"
                      (click)="unpin(pinned.id)"
                    >
                      <img
                        src="https://img.icons8.com/material-rounded/96/ffffff/pin.png"
                        width="16px"
                        class=""
                      />
                    </button>
                  </div>
                </div>
              </div>
            </small>
          </div>

          <a [href]="'/blog/' + pinned.id">
            <h5>{{ pinned.title }}</h5>
            <a class="blurb-link" [href]="'/blog/' + pinned.id">
              <div
                class="blurb ck-content"
                height="100px"
                [innerHTML]="pinned.content"
              ></div>
            </a>
          </a>
          <app-pin-blog-footer [post]="pinned"></app-pin-blog-footer>
        </div>
      </div>
    </div>
    <hr />
  </span>

  <div class="col">
    <div class="row">
      <app-blog-nav></app-blog-nav>
      <button
        *ngIf="isAdmin"
        class="btn dark-solid-button rounded-0 ml-auto"
        (click)="newPost()"
      >
        <small>New Post</small>
      </button>
    </div>
  </div>
  <div
    *ngFor="let post of posts"
    class="post row my-3"
    data-aos="fade-right"
    data-aos-once="true"
    data-aos-duration="1000"
    data-aos-offset="100"
  >
    <div class="col-sm-12 col-md-12 col-lg-6 p-0 mr-0">
      <a [href]="'/blog/' + post.id">
        <div class="" [innerHTML]="post.cover"></div>
      </a>
    </div>
    <div class="d-flex col-sm-12 col-md-12 col-lg-6 px-5 p-2">
      <div class="align-self-center" width="100%">
        <div class="mb-2">
          <small class="align-self-center">
            <div class="row justify-content-start">
              <div class="col-1 p-0 ml-3">
                <a [href]="'/user/' + post.uid + '/profile'">
                  <img
                    class="rounded-circle"
                    [src]="post.authorImg"
                    width="32px"
                  />
                </a>
              </div>
              <div class="d-flex flex-column p-0 ml-3 justify-content-center">
                <div class="">
                  <a [href]="'/user/' + post.uid + '/profile'">
                    {{ post.authorName }}
                  </a>
                </div>
                <div class="">
                  <small>
                    {{ post.time | timeParse }} &middot;
                    {{ post.readTime | readParse }}
                  </small>
                </div>
              </div>
              <div class="col-3 ml-auto" *ngIf="isAdmin">
                <div class="row">
                  <button
                    *ngIf="!post.pinned"
                    (click)="pin(post.id)"
                    class="btn my-auto pin-post rounded ml-auto"
                  >
                    <img
                      src="https://img.icons8.com/material-outlined/96/000000/pin.png"
                      width="16px"
                      class=""
                    />
                  </button>
                  <button
                    (click)="unpin(post.id)"
                    *ngIf="post.pinned"
                    class="btn my-auto pin-post rounded ml-auto"
                  >
                    <img
                      src="https://img.icons8.com/material-rounded/96/000000/pin.png"
                      width="16px"
                      class=""
                    />
                  </button>
                  <app-gear [data]="post.gearData"></app-gear>
                </div>
              </div>
            </div>
          </small>
        </div>

        <a [href]="'/blog/' + post.id">
          <h5>{{ post.title }}</h5>
          <a class="blurb-link" [href]="'/blog/' + post.id">
            <div
              class="blurb ck-content"
              height="100px"
              [innerHTML]="post.content"
            ></div>
          </a>
        </a>
        <app-blog-footer [post]="post"></app-blog-footer>
      </div>
    </div>
  </div>

  <div class="d-flex justify-content-center mt-3" *ngIf="numPages === 0">
    <i>No Posts Yet</i>
  </div>
  <div class="d-flex justify-content-center" *ngIf="numPages > 0">
    <!-- <div class="col" *ngFor="let page of numPages">
    {{ page + 1 }}
  </div> -->
    <div class="align-self-center">
      <button class="btn" (click)="goToPage(1)"><<</button>
    </div>
    <div class="align-self-center">
      <button class="btn" (click)="goToPage(page - 1)"><</button>
    </div>

    <div class="align-self-center">{{ page }} of {{ numPages }}</div>
    <div class="align-self-center">
      <button class="btn" (click)="goToPage(page + 1)">></button>
    </div>
    <div class="align-self-center">
      <button class="btn" (click)="goToPage(numPages)">>></button>
    </div>
  </div>
</div>
