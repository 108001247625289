<div class="container mt-3">
  <p>If you spot an issue or bug with the website, please contact Akathian</p>
  <p>
    Email: akathian05@gmail.com
    <br />
    Phone: 647-882-7285 <br /><a href="https://github.com/Akathian"
      >GitHub: github.com/Akathian</a
    >
    <br /><a href="https://www.instagram.com/ak.kodes/"> IG: @ak.kodes </a>
  </p>

  <p>For any other questions, contact Saranya Jegatheeswaran</p>
  <p>
    Email: s.strongertogether@gmail.com
    <br />
    Phone: 647-783-7731 <br />
    <a href="https://www.instagram.com/s.strongertogether/">
      IG: @s.strongertogether
    </a>
  </p>
</div>
