<div class="container-fluid">
  <small>
    <a href="/">Stronger Together </a> /
    <a href="/community"> Community </a>
  </small>
  <hr class="m-0" />
</div>
<div class="row my-3 p-5 m-5">
  <div class="col">
    <div class="row">
      <h1
        class="text-center text-uppercase mx-auto"
        data-aos="fade-up"
        data-aos-easing="ease-in-out"
        data-aos-duration="1000"
        data-aos-offset="300"
      >
        Community
      </h1>
    </div>
  </div>
</div>
<div class="container mt-5">
  <div class="col">
    <div class="row">
      <div class="col">
        <div class="row justify-content-center">
          <span class="">
            A safe space where you can journal about your day, your problems,
            etc., in which people can reply providing emotional support, help,
            and guidance. Together, we can promote healing, hope, strength,
            resiliency, trust, and support.
          </span>
        </div>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col">
        <div
          class="row border border-bottom-0 justify-content-end p-2 text-white"
          style="background-color: #003459"
        >
          <div class="col-6 mr-auto ml-3">
            <div class="row justify-content-start sub-title">
              <div class="mr-3 my-auto">
                <a href="/community"> All Posts </a>
              </div>
              <div class="mr-3 my-auto">
                <a href="/my-community-posts"> My Posts</a>
              </div>
              <button
                (click)="newPost()"
                class="btn primary-solid-button rounded-0"
              >
                Create New Post
              </button>
            </div>
          </div>
          <div class="col-1 text-center my-auto">
            <i class="fas fa-comments"></i>
          </div>
          <div class="col-1 text-center my-auto">
            <i class="far fa-heart"></i>
          </div>
          <div class="col-1 text-center my-auto">
            <i class="fas fa-eye"></i>
          </div>
        </div>
        <div class="row border">
          <div class="col">
            <div
              class="row border-bottom p-2 text-white"
              style="background-color: #799bb9"
            >
              <div class="col">
                <h6 class="mb-0 pb-0">Pinned Posts</h6>
              </div>
            </div>
            <div *ngFor="let forum of pinnedPosts" class="row forum p-2">
              <div class="col">
                <div class="row">
                  <div class="col-6 mr-auto">
                    <div class="row">
                      <div class="col">
                        <div class="row">
                          <button
                            *ngIf="!forum.pinned && isAdmin"
                            (click)="pin(forum.id)"
                            class="btn pin-post rounded pb-1 px-0 pt-0 ml-1 mr-2"
                          >
                            <img
                              src="https://img.icons8.com/material-outlined/96/000000/pin.png"
                              width="16px"
                              class=""
                            />
                          </button>
                          <button
                            (click)="unpin(forum.id)"
                            *ngIf="forum.pinned && isAdmin"
                            class="btn pin-post rounded pb-1 px-0 pt-0 ml-1 mr-2"
                          >
                            <img
                              src="https://img.icons8.com/material-rounded/96/000000/pin.png"
                              width="16px"
                              class=""
                            />
                          </button>

                          <button
                            *ngIf="!forum.pinned && !isAdmin"
                            class="btn pin-post rounded pb-1 px-0 pt-0 ml-1 mr-2 notAdminPin"
                          >
                            <img
                              src="https://img.icons8.com/material-outlined/96/000000/pin.png"
                              width="16px"
                              class="notAdminPin"
                            />
                          </button>
                          <button
                            *ngIf="forum.pinned && !isAdmin"
                            class="btn pin-post rounded pb-1 px-0 pt-0 ml-1 mr-2 notAdminPin"
                          >
                            <img
                              src="https://img.icons8.com/material-rounded/96/000000/pin.png"
                              width="16px"
                              class="notAdminPin"
                            />
                          </button>
                          <a
                            [href]="'/community/' + forum.id"
                            class="my-auto mr-1"
                          >
                            {{ forum.title }}
                          </a>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col ml-3">
                        <small>
                          <small>
                            <a
                              [href]="'/user/' + forum.uid + '/profile'"
                              *ngIf="
                                forum.authorName !== 'Anonymous' || isAdmin
                              "
                            >
                              {{ forum.authorName }}
                            </a>
                            <a
                              *ngIf="
                                forum.authorName === 'Anonymous' && !isAdmin
                              "
                            >
                              {{ forum.authorName }}</a
                            >
                          </small>
                        </small>
                      </div>
                    </div>
                  </div>
                  <div class="col-1 text-center my-auto">
                    {{ forum.numComments }}
                  </div>
                  <div class="col-1 text-center my-auto">
                    {{ forum.numLikes }}
                  </div>
                  <div class="col-1 text-center my-auto">
                    {{ forum.numViews }}
                  </div>
                </div>
              </div>

              <div class="col-12">
                <hr class="m-0" />
              </div>
            </div>
            <div
              class="row border-bottom border-top p-2 justify-content-center"
              *ngIf="!pinnedPosts.length"
            >
              <div class="col text-center">
                <i> No Pinned Posts </i>
              </div>
            </div>
            <div
              class="row border-bottom border-top p-2 text-white"
              style="background-color: #799bb9"
            >
              <div class="col">
                <h6 class="mb-0 pb-0">All Posts</h6>
              </div>
            </div>
            <div *ngFor="let forum of posts" class="row forum p-2">
              <div class="col">
                <div class="row">
                  <div class="col-6 mr-auto">
                    <div class="row">
                      <div class="col">
                        <div class="row">
                          <button
                            *ngIf="!forum.pinned && isAdmin"
                            (click)="pin(forum.id)"
                            class="btn pin-post rounded pb-1 px-0 pt-0 ml-1 mr-2"
                          >
                            <img
                              src="https://img.icons8.com/material-outlined/96/000000/pin.png"
                              width="16px"
                              class=""
                            />
                          </button>
                          <button
                            (click)="unpin(forum.id)"
                            *ngIf="forum.pinned && isAdmin"
                            class="btn pin-post rounded pb-1 px-0 pt-0 ml-1 mr-2"
                          >
                            <img
                              src="https://img.icons8.com/material-rounded/96/000000/pin.png"
                              width="16px"
                              class=""
                            />
                          </button>
                          <button
                            *ngIf="!forum.pinned && !isAdmin"
                            class="btn pin-post rounded pb-1 px-0 pt-0 ml-1 mr-2 notAdminPin"
                          >
                            <img
                              src="https://img.icons8.com/material-outlined/96/000000/pin.png"
                              width="16px"
                              class="notAdminPin"
                            />
                          </button>
                          <button
                            *ngIf="forum.pinned && !isAdmin"
                            class="btn pin-post rounded pb-1 px-0 pt-0 ml-1 mr-2 notAdminPin"
                          >
                            <img
                              src="https://img.icons8.com/material-rounded/96/000000/pin.png"
                              width="16px"
                              class="notAdminPin"
                            />
                          </button>
                          <a
                            [href]="'/community/' + forum.id"
                            class="my-auto mr-1"
                          >
                            {{ forum.title }}
                          </a>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col ml-3">
                        <small>
                          <small>
                            <a
                              [href]="'/user/' + forum.uid + '/profile'"
                              *ngIf="
                                forum.authorName !== 'Anonymous' || isAdmin
                              "
                            >
                              {{ forum.authorName }}
                            </a>
                            <a
                              *ngIf="
                                forum.authorName === 'Anonymous' && !isAdmin
                              "
                            >
                              {{ forum.authorName }}</a
                            >
                          </small>
                        </small>
                      </div>
                    </div>
                  </div>
                  <div class="col-1 text-center my-auto">
                    {{ forum.numComments }}
                  </div>
                  <div class="col-1 text-center my-auto">
                    {{ forum.numLikes }}
                  </div>
                  <div class="col-1 text-center my-auto">
                    {{ forum.numViews }}
                  </div>
                </div>
              </div>

              <div class="col-12">
                <hr class="m-0" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="d-flex justify-content-center mt-3" *ngIf="numPages === 0">
    <i>No Posts Yet</i>
  </div>
  <div class="d-flex justify-content-center" *ngIf="numPages > 0">
    <!-- <div class="col" *ngFor="let page of numPages">
    {{ page + 1 }}
  </div> -->
    <div class="align-self-center">
      <button class="btn" (click)="goToPage(1)"><<</button>
    </div>
    <div class="align-self-center">
      <button class="btn" (click)="goToPage(page - 1)"><</button>
    </div>

    <div class="align-self-center">{{ page }} of {{ numPages }}</div>
    <div class="align-self-center">
      <button class="btn" (click)="goToPage(page + 1)">></button>
    </div>
    <div class="align-self-center">
      <button class="btn" (click)="goToPage(numPages)">>></button>
    </div>
  </div>
</div>
