<div
  class="row justify-content-center my-3 text-white"
  style="background-color: #003459"
>
  <div
    data-aos="fade-right"
    data-aos-duration="1000"
    data-aos-once="true"
    data-aos-offset="150"
    class="col-sm-10 col-md-10 col-lg-5 p-4 align-self-center"
  >
    <h3 class="text-uppercase">Peer Buddy support</h3>
    Want to make a friend? Need some encouragement or peer support to help you?
    Please remember, that signing up for the peer buddy support program does not
    replace therapy. If you require professional support, please call crisis
    lines, your family physician, and/or other mental health resources, which
    can be found in our extensive resource list on our blog. Please fill out the
    submission form, so that we can get started with pairing you up with your
    buddy!
  </div>
  <div
    class="d-flex col-sm-10 col-md-10 col-lg-5 px-5 p-2 text-center"
    data-aos="fade"
    data-aos-duration="1000"
    data-aos-once="true"
    data-aos-offset="200"
  >
    <div class="align-self-center" *ngIf="!sent">
      <form
        [formGroup]="peerBuddyForm"
        (ngSubmit)="onSubmit(peerBuddyForm.value)"
        class="p-5 mb-3"
      >
        <div class="container-fluid">
          <div class="row">
            <div class="col mr-1" id="peer-name">
              <div class="row">
                <label>
                  Name:
                  <span class="text-danger small" *ngIf="nameErr"
                    >*Required</span
                  >
                </label>
                <input
                  type="text"
                  class="form-control mb-4 rounded-0"
                  placeholder="Name"
                  formControlName="name"
                />
              </div>
            </div>
            <div class="col" id="peer-age">
              <div class="row">
                <label>
                  Age:
                  <span class="text-danger small" *ngIf="">*Required</span>
                </label>
                <input
                  type="text"
                  class="form-control mb-4 rounded-0"
                  placeholder="Age"
                  formControlName="age"
                />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col" id="peer-idAs">
              <div class="row">
                <label>
                  I identify as:
                  <span class="text-danger small" *ngIf="identifyErr"
                    >*Required</span
                  >
                </label>
                <input
                  type="text"
                  class="form-control mb-4 rounded-0"
                  placeholder="Male, Female, LGBTQQIP2SAA..."
                  formControlName="identify"
                />
              </div>
            </div>
          </div>
          <div class="row" id="peer-pref">
            <div class="col" id="peer-name">
              <div class="row">
                <label>
                  Specify preferences on who you’d like to speak to:
                  <span class="text-danger small" *ngIf="">*Required</span>
                </label>
                <input
                  type="text"
                  class="form-control mb-4 rounded-0"
                  placeholder=""
                  formControlName="pref"
                />
              </div>
            </div>
          </div>
          <div class="row" id="peer-contact">
            <div class="col mr-1" id="peer-contact-method">
              <div class="row">
                <label>
                  Contact Method:
                  <span class="text-danger small" *ngIf="contactMethodErr"
                    >*Required</span
                  >
                </label>
                <input
                  type="text"
                  class="form-control mb-4 rounded-0"
                  placeholder="Phone, Email, Instagram, etc."
                  formControlName="contactMethod"
                />
              </div>
            </div>
            <div class="col" id="peer-name">
              <div class="row">
                <label>
                  Contact Info:
                  <span class="text-danger small" *ngIf="contactInfoErr"
                    >*Required</span
                  >
                </label>
                <input
                  type="text"
                  class="form-control mb-4 rounded-0"
                  placeholder="(555) 555-5555, example@domain.com"
                  formControlName="contactInfo"
                />
              </div>
            </div>
          </div>
        </div>
        <button
          class="btn primary-solid-button btn-block rounded-0"
          id="peerSubmit"
          type="submit"
        >
          <span *ngIf="!sent && !showLoading && !error">Submit</span>
          <img
            src="../../../../assets/loading.gif"
            height="16px"
            *ngIf="showLoading"
          />
          <span *ngIf="sent && !showLoading && !error">Sent!</span>
          <span *ngIf="error && !showLoading">Error, try again</span>
        </button>
      </form>
    </div>
    <div class="align-self-center" *ngIf="sent">
      Thank you for your submission. You will hear back from us shortly about
      your peer buddy.
    </div>
  </div>
</div>
