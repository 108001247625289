<div
  class="row justify-content-center my-3 text-white"
  style="background-color: #003459"
>
  <div
    class="col-sm-10 col-md-10 col-lg-5 p-4 align-self-center"
    data-aos="fade-right"
    data-aos-duration="1000"
    data-aos-once="true"
    data-aos-offset="150"
  >
    <h3 class="text-uppercase">Life Coaching</h3>
    Welcome to Stronger Together Inspires. Are you looking to seek help to
    further your life to the next level? Stronger Together Inspires is here to
    provide life coaching sessions to help you achieve the life you’re dreaming
    of! As a coach, my main focus is YOU. To start, I offer FREE 30 minute
    consultations for you to decide if you would like to start this journey with
    me, and to see if we are a good fit! If you are looking to further explore
    life coaching with me, leave your information here and I will contact you as
    soon as possible! BOOK YOUR FREE CONSULTATION NOW!
  </div>
  <div
    class="d-flex col-sm-10 col-md-10 col-lg-5 px-5 p-2 text-center"
    data-aos="fade"
    data-aos-duration="1000"
    data-aos-once="true"
    data-aos-offset="200"
  >
    <div class="align-self-center" *ngIf="!sent">
      <form
        [formGroup]="lifeCoachForm"
        (ngSubmit)="onSubmit(lifeCoachForm.value)"
        class="p-5 mb-3"
      >
        <div class="container-fluid">
          <div class="row">
            <div class="col mr-1" id="life-fname">
              <div class="row">
                <label>
                  First Name:
                  <span class="text-danger small" *ngIf="fnameErr"
                    >*Required</span
                  >
                </label>
                <input
                  type="text"
                  class="form-control mb-4 rounded-0"
                  placeholder="First Name"
                  formControlName="fname"
                />
              </div>
            </div>
            <div class="col" id="life-lname">
              <div class="row">
                <label>
                  Last Name:
                  <span class="text-danger small" *ngIf="lnameErr"
                    >*Required</span
                  >
                </label>
                <input
                  type="text"
                  class="form-control mb-4 rounded-0"
                  placeholder="Last Name"
                  formControlName="lname"
                />
              </div>
            </div>
          </div>
          <div class="row" id="life-contact">
            <div class="col mr-1" id="life-email">
              <div class="row">
                <label>
                  Email Address:
                  <span class="text-danger small" *ngIf="emailErr"
                    >*Required</span
                  >
                </label>
                <input
                  type="text"
                  class="form-control mb-4 rounded-0"
                  placeholder="example@domain.com"
                  formControlName="email"
                />
              </div>
            </div>
            <div class="col" id="life-phone">
              <div class="row">
                <label>
                  Phone Number:
                  <span class="text-danger small" *ngIf="">*Required</span>
                </label>
                <input
                  type="text"
                  class="form-control mb-4 rounded-0"
                  placeholder="(555) 555-5555"
                  formControlName="phone"
                />
              </div>
            </div>
          </div>
        </div>
        <button
          class="btn primary-solid-button btn-block rounded-0"
          id="lifeSubmit"
          type="submit"
        >
          <span *ngIf="!sent && !showLoading && !error">Submit</span>
          <img
            src="../../../../assets/loading.gif"
            height="16px"
            *ngIf="showLoading"
          />
          <span *ngIf="sent && !showLoading && !error">Sent!</span>
          <span *ngIf="error && !showLoading">Error, try again</span>
        </button>
      </form>
    </div>
    <div class="align-self-center" *ngIf="sent">
      Thank you for your submission. You will hear back from us shortly about
      your life coaching sessions.
    </div>
  </div>
</div>
