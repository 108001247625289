<div class="col count">
  <div class="row">
    <div
      class="col-lg-6 col-md-12 col-sm-12 text-white side d-flex flex-column justify-content-center"
      style="background-color: rgba(0, 0, 0, 0.95)"
    >
      <div class="d-flex row justify-content-center timer">
        <div class="mr-5">
          <div class="row">
            <div class="col">
              <div class="row" id="days"></div>
              <div class="row"><span class="mx-auto">Days</span></div>
            </div>
          </div>
        </div>
        <div class="mr-5">
          <div class="row">
            <div class="col">
              <div class="row" id="hours"></div>
              <div class="row"><span class="mx-auto">Hours</span></div>
            </div>
          </div>
        </div>
        <div class="mr-5">
          <div class="row">
            <div class="col">
              <div class="row" id="mins"></div>
              <div class="row"><span class="mx-auto">Minutes</span></div>
            </div>
          </div>
        </div>
        <div class="">
          <div class="row">
            <div class="col">
              <div class="row" id="secs"></div>
              <div class="row"><span class="mx-auto">Seconds</span></div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="d-flex row mt-5 pt-3 justify-content-center">
          <div class="col-6">
            <img class="mx-auto" src="assets/logo-whiteonly.png" width="100%" />
          </div>
        </div> -->
      <div class="row mt-5 pt-3">
        <div class="col">
          <h1 class="mx-auto text-uppercase coming-soon">Coming Soon</h1>
          <hr class="bg-white my-5" width="300px" />
        </div>
      </div>

      <div class="row">
        <p class="col mx-auto text-center tag">
          Healing ~ Hope ~ Strength ~ Resiliency ~ Trust ~ Support
        </p>
      </div>
    </div>
    <div
      class="col-xl-6 col-lg-6 col-md-12 col-sm-12 text-white side d-flex flex-column justify-content-center"
      style="background-color: #799bb9"
    >
      <div class="row my-2">
        <div class="col-6 mx-auto">
          <img class="rounded-circle" src="assets/STLOGO.png" width="50%" />
        </div>
      </div>
      <div class="row my-2">
        <div class="col">
          <h1 class="mx-auto">Contact Us</h1>
          <hr class="mb-2 mt-0 bg-white" width="300px" />
        </div>
      </div>
      <div class="row my-2">
        <div class="col mx-auto text-center tag">
          Feel free to contact us using any of the links below and we will get
          back to you as soon as we can
        </div>
      </div>
      <div class="row my-2 links">
        <div class="col">
          <a href="https://www.instagram.com/s.strongertogether/">
            <i class="fab fa-instagram"></i> s.strongertogether
          </a>
        </div>
      </div>
      <div class="row my-2 links">
        <div class="col">
          <a href="mailto:s.strongertogether@gmail.com">
            <i class="fas fa-envelope"></i> s.strongertogether@gmail.com
          </a>
        </div>
      </div>
      <div class="row my-2 links">
        <div class="col">
          <a href="https://www.facebook.com/Stronger.Together100">
            <i class="fab fa-facebook-square"></i> Stronger.Together100
          </a>
        </div>
      </div>
      <div class="row my-2 links">
        <div class="col">
          <a href="https://www.linkedin.com/company/s-stronger-together/">
            <i class="fab fa-linkedin"></i> Stronger Together
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
