<!-- <div class="col p-0"> -->
<div
  class="row text-white p-4 mr-0 justify-content-start sub-title"
  style="background-color: #799bb9"
>
  <!-- <div class="row"> -->
  <div class="col-12 d-flex flex-row">
    <div class="align-self-center mr-3">
      <img width="64px" src="../../../../assets/STLOGO.png" />
    </div>
    <div class="col align-self-center">
      Becoming Stronger Together, while changing the narrative around mental
      health.
    </div>
  </div>
</div>
<!-- </div> -->

<div
  *ngIf="width > 587"
  class="col pl-5 py-2 sub-title"
  style="border-bottom: 5px solid #799bb9"
>
  <div class="row">
    <div class="col d-flex flex-row">
      <div class="row">
        <div class="col">
          <a href="/">Home</a>
        </div>
        <div class="col">
          <a href="/blog">Blog</a>
        </div>
        <div class="col">
          <a href="/services">Services</a>
        </div>
        <div class="col">
          <a href="/community">Community</a>
        </div>
        <div class="col">
          <a href="/about">About</a>
        </div>
        <div class="col">
          <a href="/#contact">Contact</a>
        </div>
      </div>
    </div>
    <div class="col-1 d-flex flex-row justify-content-end">
      <div class="row mr-3">
        <a *ngIf="!uid" href="/login" [innerHTML]="profilePic | sanitizeHtml">
          <svg
            id="login"
            width="1.5em"
            height="1.5em"
            viewBox="0 0 16 16"
            class="bi bi-person-circle"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M13.468 12.37C12.758 11.226 11.195 10 8 10s-4.757 1.225-5.468 2.37A6.987 6.987 0 0 0 8 15a6.987 6.987 0 0 0 5.468-2.63z"
            />
            <path fill-rule="evenodd" d="M8 9a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
            <path
              fill-rule="evenodd"
              d="M8 1a7 7 0 1 0 0 14A7 7 0 0 0 8 1zM0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8z"
            />
          </svg>
        </a>
        <a
          *ngIf="uid"
          [href]="'/user/' + uid + '/profile'"
          [innerHTML]="profilePic | sanitizeHtml"
        >
        </a>
      </div>
    </div>
  </div>
</div>

<span *ngIf="width <= 587">
  <div class="mb-3 sub-title" style="border-bottom: 5px solid #799bb9">
    <div role="tab">
      <div class="mb-0 d-flex justify-content-center">
        <a
          data-toggle="collapse"
          href="#smallNav"
          aria-expanded="false"
          class="collapsed"
        >
        </a>
      </div>
    </div>
    <div id="smallNav" class="collapse" role="tabpanel">
      <div class="col">
        <div class="row justify-content-center p-1 navItem">
          <a href="/">Home</a>
        </div>
        <div class="row justify-content-center p-1 navItem">
          <a href="/blog">Blog</a>
        </div>
        <div class="row justify-content-center p-1 navItem">
          <a href="/services">Services</a>
        </div>
        <div class="row justify-content-center p-1 navItem">
          <a href="/community">Community</a>
        </div>
        <div class="row justify-content-center p-1 navItem">
          <a href="/about">About</a>
        </div>
        <div class="row justify-content-center p-1 navItem">
          <a href="/#contact">Contact</a>
        </div>
        <div class="row justify-content-center p-1 navItem mb-1">
          <a *ngIf="!uid" href="/login" [innerHTML]="profilePic | sanitizeHtml">
            <svg
              id="login"
              width="1.5em"
              height="1.5em"
              viewBox="0 0 16 16"
              class="bi bi-person-circle"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13.468 12.37C12.758 11.226 11.195 10 8 10s-4.757 1.225-5.468 2.37A6.987 6.987 0 0 0 8 15a6.987 6.987 0 0 0 5.468-2.63z"
              />
              <path fill-rule="evenodd" d="M8 9a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
              <path
                fill-rule="evenodd"
                d="M8 1a7 7 0 1 0 0 14A7 7 0 0 0 8 1zM0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8z"
              />
            </svg>
          </a>
          <a
            *ngIf="uid"
            [href]="'/user/' + uid + '/profile'"
            [innerHTML]="profilePic | sanitizeHtml"
          >
          </a>
        </div>
      </div>
    </div>
  </div>
</span>
<!-- </div> -->
