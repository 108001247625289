<div class="p-5" style="background-color: #003459">
  <div class="container text-white">
    <div class="row">
      <div class="col-sm-12 col-lg-6 col-xl-4">
        <img src="assets/STLOGO.png" alt="" width="100%" />
      </div>
      <div class="col align-self-center">
        <div class="row justify-content-center">
          <div class="col">
            <h2
              class="text-center text-uppercase"
              data-aos="fade-down"
              data-aos-once="true"
              data-aos-offset="100"
            >
              STRONGER TOGETHER AT A GLANCE
            </h2>
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col">
            <p
              style="max-width: 750px"
              class="text-center align-self-center"
              data-aos="fade-up"
              data-aos-once="true"
              data-aos-offset="100"
            >
              Stronger Together was founded in October 2019 and continues to
              grow with the support of the community. Here at Stronger Together,
              we believe in creating a community where no-one is left behind. We
              continuously try to raise awareness, provide motivation and
              support, and break down stigma. As you navigate through our
              website, you will be able to learn about the various services we
              offer, read our blog posts, and the forum page which will allow
              you to journal, connect, and discuss with one another. Our shop
              will be coming soon, and its primary goal will be to provide
              essentials that will promote well-being, and boost self-care - a
              portion of all proceeds will be donated to Centre for Addiction
              and Mental Health (CAMH).
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
