<div class="container-fluid">
  <small>
    <a href="/">Stronger Together </a> /
    <a href="/about"> About </a>
  </small>
  <hr class="m-0" />
</div>
<div class="row my-3 p-5 m-5">
  <div class="col">
    <div class="row">
      <h1
        class="text-center text-uppercase mx-auto"
        data-aos="fade-up"
        data-aos-easing="ease-in-out"
        data-aos-duration="1000"
        data-aos-offset="300"
      >
        About
      </h1>
    </div>
  </div>
</div>

<div style="background-color: #003459" class="text-white">
  <div class="container my-5 p-5">
    <div class="col">
      <div class="row align-items-center">
        <div class="col-sm-12 col-md-4 mb-3">
          <img
            data-aos="fade-right"
            data-aos-easing="ease-in-out"
            data-aos-duration="1000"
            data-aos-offset="300"
            src="assets/STLOGO.png"
            width="100%"
            height="auto"
            class=""
          />
        </div>
        <div class="col border-left pl-4">
          <h2
            data-aos="fade"
            data-aos-easing="ease-in-out"
            data-aos-duration="1000"
            data-aos-offset="300"
          >
            Bio
          </h2>
          <p
            class="text"
            data-aos="fade"
            data-aos-easing="ease-in-out"
            data-aos-duration="1000"
            data-aos-offset="300"
          >
            Stronger Together - a platform to spread awareness around mental
            health. With the goal of fostering a safe and supportive environment
            where individuals can openly share their experiences, we aim to
            create a community where no-one is left behind. The primary goal is
            to raise awareness, provide support and resources, and to spread the
            message that you are not in this alone. By sharing lived experiences
            through photographs accompanied by stories that have been shared
            with us - some anonymous and others not, we hope to engage
            conversations within the community. Inspirational quotes will be
            shared to promote motivation and healing, and educational posts on
            mental health and illness topics to create awareness.
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="container my-5">
  <div class="row">
    <div
      class="col-lg-4 col-md-4 col-sm-4 col-xs-12 my-2"
      data-aos="fade-right"
      data-aos-easing="ease-in-out"
      data-aos-duration="1000"
      data-aos-delay="0"
    >
      <div class="box-part text-center">
        <div class="title">
          <img src="assets/target.png" width="32px" />
          <h4 class="">Mission</h4>
        </div>
        <div class="text text-muted">
          <span>
            Raise awareness, help break down stigma, and offer support around
            mental health.
          </span>
        </div>
      </div>
    </div>
    <div
      class="col-lg-4 col-md-4 col-sm-4 col-xs-12 my-2"
      data-aos="fade-right"
      data-aos-easing="ease-in-out"
      data-aos-duration="1000"
      data-aos-delay="200"
    >
      <div class="box-part text-center">
        <div class="title">
          <img src="assets/eye.png" width="32px" />
          <h4 class="">Vision</h4>
        </div>
        <div class="text text-muted">
          <span>
            Foster a safe and supportive environment, and build a community in
            which no-one is left behind.
          </span>
        </div>
      </div>
    </div>
    <div
      class="col-lg-4 col-md-4 col-sm-4 col-xs-12 my-2"
      data-aos="fade-right"
      data-aos-easing="ease-in-out"
      data-aos-duration="1000"
      data-aos-delay="400"
    >
      <div class="box-part text-center">
        <div class="title">
          <img src="assets/core.svg" width="32px" />
          <h4 class="">Values</h4>
        </div>
        <div class="text text-muted">
          <span>
            Promote healing, hope, strength, resiliency, trust, and support.
          </span>
        </div>
      </div>
    </div>
  </div>
</div>
<div
  class="container-fluid text-white"
  style="background-color: #003459"
  *ngIf="width > 767"
>
  <div class="container">
    <div class="col mx-auto">
      <div class="row">
        <div class="col">
          <div
            data-aos="fade"
            data-aos-easing="ease-in-out"
            data-aos-duration="1000"
            class="row"
          ></div>
          <div
            class="row text-center justify-content-center"
            data-aos="fade"
            data-aos-easing="ease-in-out"
            data-aos-duration="1000"
          >
            <div class="col-sm-6 col-xs-6 col-md-8 col-lg-8 my-auto p-2">
              <h2 class="text-center">Meet the Founder!</h2>
              Hi everyone! My name is Saranya Jegatheeswaran, and I am an
              aspiring Nurse, and the founder of Stronger Together. As someone
              who has struggled greatly with my own mental health throughout my
              life, I aspire to make sure that others have the support and
              resources needed to access the care they need. My compassionate
              nature, interests in helping others, and passion for mental health
              awareness drove me to pursue a career in healthcare, coach people
              to attain the life they dream to live, and create a platform that
              seeks to raise mental health awareness. Stronger Together further
              reinforces this by providing individuals with a sense of community
              so that they don’t feel alone. My passion to help others, and
              create outstanding changes within the community and the world has
              led me to be an active mental health advocate and seek out
              opportunities in which I can help create a better future to live
              in. I believe in giving back, spreading love, creating awareness,
              and breaking down stigma on important stigmatized and taboo
              topics.
            </div>
            <div class="col-sm-6 col-xs-6 col-md-4 col-lg-3 my-auto p-2">
              <img src="assets/my-pic.jpeg" alt="" width="100%" class="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  class="container-fluid text-white"
  style="background-color: #003459"
  *ngIf="width <= 767"
>
  <div class="col mx-auto">
    <div class="row">
      <div class="col">
        <div
          class="row text-center justify-content-center"
          data-aos="fade"
          data-aos-easing="ease-in-out"
          data-aos-duration="1000"
        >
          <div class="col-sm-12 col-xs-12 col-md-6 col-lg-8 my-auto p-2">
            <h2 class="text-center">Meet the Founder!</h2>
            Hi everyone! My name is Saranya Jegatheeswaran, and I am an aspiring
            Nurse, and the founder of Stronger Together. As someone who has
            struggled greatly with my own mental health throughout my life, I
            aspire to make sure that others have the support and resources
            needed to access the care they need. My compassionate nature,
            interests in helping others, and passion for mental health awareness
            drove me to pursue a career in healthcare, coach people to attain
            the life they dream to live, and create a platform that seeks to
            raise mental health awareness. Stronger Together further reinforces
            this by providing individuals with a sense of community so that they
            don’t feel alone. My passion to help others, and create outstanding
            changes within the community and the world has led me to be an
            active mental health advocate and seek out opportunities in which I
            can help create a better future to live in. I believe in giving
            back, spreading love, creating awareness, and breaking down stigma
            on important stigmatized and taboo topics.
          </div>
          <div class="col-sm-12 col-xs-12 col-md-6 col-lg-3 my-auto p-2">
            <img src="assets/my-pic.jpeg" alt="" width="100%" class="my-auto" />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
